import "./App.scss";
import React from "react";

import { useState, useEffect } from "react";
import { Image, Navbar } from "./components";
import Routes from "./Routes";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import queryClient from "./react-query-client";
import { Footer } from "./components";
import { firestore } from "./config/firbase";
import {
	FirebaseContext,
	AuthenticationConext,
} from "./config/FirestoreContext";
import { getAuth } from "firebase/auth";
import HotNews from "hotNews";

import { referenceLinks } from "config/externalLinks";
import whatsAppIcon from "assets/whatsapp.svg";
import notificationIcon from "assets/notification-icon.png";
import chatbotIcon from "assets/chat-bot-image.webp";
import { AppContentContext } from "config/ContentContext";
import generateAppContent from "websiteContent/main";
import { getDocumentsData } from "databaseConfig/dbConfig";
import { HOTNEWS } from "constants/dbConstants";
import ErrorBoundary from "ErrorBoundary";
import Chatbot from "pages/chatbot/Chatbot";

function App() {
	const [showHotNews, setShowHotNews] = useState(true);
	const [isChatOpen, setIsChatOpen] = useState(false);


	const [hotNewsData, setHotNewsData] = useState({});

	useEffect(() => {
		getDocumentsData(HOTNEWS).then((resp) => {
			setHotNewsData(resp);
		});
	}, []);

	return (
		<div className="app">
			<QueryClientProvider client={queryClient}>
				<FirebaseContext.Provider value={firestore}>
					<AuthenticationConext.Provider value={getAuth()}>
						<AppContentContext.Provider value={generateAppContent()}>
							<div className="app-content">
								<Navbar />
								<ErrorBoundary>
									<Routes />
								</ErrorBoundary>

								<Footer />
								{hotNewsData.length > 0 && (
									<HotNews
										openHotNews={showHotNews}
										setOpenHotNews={setShowHotNews}
										hotNewsData={hotNewsData}
									/>
								)}
								{
								isChatOpen && (
								<Chatbot isChatOpen={isChatOpen} setIsChatOpen={setIsChatOpen}/>
								)
								}
							</div>
							<ReactQueryDevtools initialIsOpen={false} />
						</AppContentContext.Provider>
					</AuthenticationConext.Provider>
				</FirebaseContext.Provider>
			</QueryClientProvider>
			{!showHotNews && hotNewsData.length > 0 && (
				<Image
					className="hotNotification pointer"
					src={notificationIcon}
					alt={"notification-icon"}
					onClick={() => {
						setShowHotNews(!showHotNews);
					}}
				/>
			)}

			<Image
				className="chatIcon pointer"
				src={chatbotIcon}
				alt={"chat-icon"}
				onClick={() => {
					setIsChatOpen((preVal)=>!preVal);
				}}
			/>

			<a
				className="whatsAppBtn"
				href={referenceLinks.WHATSAPP}
				target="_blank"
				rel="noreferrer"
			>
				<Image
					className="whatsAppIcon"
					src={whatsAppIcon}
					alt={"whatsapp-chat"}
				/>
			</a>
		</div>
	);
}

export default App;
