import React from 'react'
import ChatBot from 'react-simple-chatbot';
import { Modal } from "@mui/material";
import "../../hotNews.scss";
import { CloseOutlined } from "@mui/icons-material";
import { saveDocData } from "databaseConfig/dbConfig";
import { CONNECTWITHUS } from "constants/dbConstants";

const Chatbot = ({isChatOpen, setIsChatOpen, }) => {
  
  const steps = [
    // Initial greeting and options
    { id: '1', message: 'Hi! Welcome to Nybble Technosoft. How can we assist you today?', trigger: '2' },
    {
      id: '2',
      options: [
        { value: 'Study Abroad', label: 'Study Abroad', trigger: 'studyAbroad1' },
        { value: 'Training', label: 'Training', trigger: 'training1' },
        { value: 'IT Staffing', label: 'IT Staffing', trigger: 'staffing1' },
        { value: 'Web Development', label: 'Web Development', trigger: 'webDev1' }
      ]
    },
  
  // Study Abroad Section
  // Initial prompt and greeting for Study Abroad
  { id: 'studyAbroad1', message: 'Get started with FREE counseling today!', trigger: 'studyAbroad2' },

  // User name for personalization
  { id: 'studyAbroad2', message: 'May I have your name to personalize our conversation?', trigger: 'studyAbroadName' },
  { id: 'studyAbroadName', user: true, trigger: 'studyAbroadEmail' },

  // User email for further contact
  { id: 'studyAbroadEmail', message: 'Could you provide your email address for us to connect with you?', trigger: 'studyAbroadEmailInput' },
  { id: 'studyAbroadEmailInput', user: true, trigger: 'studyAbroadContact' },

  // User contact number
  { id: 'studyAbroadContact', message: 'What’s the best contact number to reach you?', trigger: 'studyAbroadContactInput' },
  { id: 'studyAbroadContactInput', user: true, trigger: 'studyAbroadDestination' },

  // Study destination selection
  { id: 'studyAbroadDestination', message: 'Which study destination are you most interested in exploring?', trigger: 'studyAbroadDestinationOptions' },

  {
    id: 'studyAbroadDestinationOptions',
    options: [
      { value: 'Australia', label: 'Australia', trigger: 'studyAbroadStartDate' },
      { value: 'Canada', label: 'Canada', trigger: 'studyAbroadStartDate' },
      { value: 'Ireland', label: 'Ireland', trigger: 'studyAbroadStartDate' },
      { value: 'New Zealand', label: 'New Zealand', trigger: 'studyAbroadStartDate' },
      { value: 'UK', label: 'UK', trigger: 'studyAbroadStartDate' },
      { value: 'United States', label: 'United States', trigger: 'studyAbroadStartDate' },
    ]
  },

  // Start date for studies
  { id: 'studyAbroadStartDate', message: 'When do you plan to start your studies? Please enter the month and year.', trigger: 'studyAbroadStartDateInput' },
  { id: 'studyAbroadStartDateInput', user: true, trigger: 'studyAbroadOffice' },

  // Nearest NTS office selection
  { 
    id: 'studyAbroadOffice', 
    message: 'Could you select the nearest NTS office to you?', 
    trigger:'studyAbroadOfficeOptions'
  },

  {
    id: 'studyAbroadOfficeOptions',
    options: [
      { value: 'Vijayawada', label: 'Vijayawada', trigger: 'studyAbroadCounselingMode' },
      { value: 'Hyderabad', label: 'Hyderabad', trigger: 'studyAbroadCounselingMode' }
    ]
  },

  // Preferred mode of counseling
  { id: 'studyAbroadCounselingMode', message: 'What is your preferred mode of counseling? (e.g., in-person, online)', trigger: 'studyAbroadCounselingModeOptions' },

  {
    id: 'studyAbroadCounselingModeOptions',
    options: [
      { value: 'In-Person', label: 'In-Person', trigger: 'studyAbroadStudyLevel' },
      { value: 'Virtual Counselling', label: 'Virtual Counselling', trigger: 'studyAbroadStudyLevel' }
    ]
  },

  // Level of study
  { id: 'studyAbroadStudyLevel', message: 'What level of study are you interested in pursuing?', trigger: 'studyAbroadStudyLevelOptions' },

  {
    id: 'studyAbroadStudyLevelOptions',
    options: [
      { value: 'English Language', label: 'English Language', trigger: 'studyAbroadFunding' },
      { value: 'School', label: 'School', trigger: 'studyAbroadFunding' },
      { value: 'Undergraduate', label: 'Undergraduate', trigger: 'studyAbroadFunding' },
      { value: 'Postgraduate', label: 'Postgraduate', trigger: 'studyAbroadFunding' },
      { value: 'Doctorate', label: 'Doctorate', trigger: 'studyAbroadFunding' },
      { value: 'Vocational', label: 'Vocational', trigger: 'studyAbroadFunding' },
      { value: 'University Preparation', label: 'University Preparation', trigger: 'studyAbroadFunding' }
    ]
  },

  // Education funding method
  { id: 'studyAbroadFunding', message: 'How do you plan to fund your education?', trigger: 'studyAbroadFundingOptions' },


  {
    id: 'studyAbroadFundingOptions',
    options: [
      { value: 'Self-Funded', label: 'Self-Funded', trigger: 'studyAbroadUpdates' },
      { value: 'Parents', label: 'Parents', trigger: 'studyAbroadUpdates' },
      { value: 'Seeking Scholarship', label: 'Seeking Scholarship', trigger: 'studyAbroadUpdates' },
      { value: 'Seeking Government Scholarship', label: 'Seeking Government Scholarship', trigger: 'studyAbroadUpdates' },
      { value: 'Have Government Scholarship', label: 'Have Government Scholarship', trigger: 'studyAbroadUpdates' },
      { value: 'Bank Loan', label: 'Bank Loan', trigger: 'studyAbroadUpdates' },
      { value: 'Other', label: 'Other', trigger: 'studyAbroadUpdates' },
      { value: 'Employer Scholarship', label: 'Employer Scholarship', trigger: 'studyAbroadUpdates' }
    ]
  },
  // Option to receive updates and offers
  {
    id: 'studyAbroadUpdates',
    message: 'Would you like to receive updates and special offers from NTS?',
   trigger:'studyAbroadUpdatesOptions'
  },

  {
    id: 'studyAbroadUpdatesOptions',
    options: [
      { value: 'Yes', label: 'Yes', trigger: 'endMessage' },
      { value: 'No', label: 'No', trigger: 'endMessage' }
    ]
  },

  // End message
  { id: 'endMessage', message: 'Thank you for your information! Our team will reach out to you soon. Have a great day!', end: true },



  

    // IT Training Section
    // Initial greeting for IT Training
  { id: 'training1', message: 'Hello! Welcome to NYBBLE TECHNOSOFT! May I know which course you’re interested in today?', trigger: 'itTrainingCourse' },
  { id: 'itTrainingCourse', user: true, trigger: 'itTrainingAdvisor' },

  // Connect with a course advisor
  { id: 'itTrainingAdvisor', message: 'Great! Can I connect you with a course advisor who can provide more details?', trigger: 'itTrainingAdvisor1' },
  { id: 'itTrainingAdvisor1', user: true, trigger: 'itTrainingContactInfo' },

  // User name, contact number, and email for proceeding
  { id: 'itTrainingContactInfo', message: 'Could you please provide your name, contact number, and email address to proceed?', trigger: 'itTrainingName' },
  { id: 'itTrainingName', user: true, trigger: 'itTrainingExperienceLevel' },

  // Experience level in the IT industry
  { id: 'itTrainingExperienceLevel', message: 'Thank you! May I know your current experience level in the IT industry? (e.g., beginner, intermediate, advanced)', trigger: 'itTrainingExperienceInput' },
  { id: 'itTrainingExperienceInput', user: true, trigger: 'itTrainingTechInterest' },

  // Technology or area of interest
  { id: 'itTrainingTechInterest', message: 'What technology or area are you currently working on or interested in learning more about?', trigger: 'itTrainingTechInput' },
  { id: 'itTrainingTechInput', user: true, trigger: 'itTrainingUpskill' },

  // Upskill or explore a new field
  { id: 'itTrainingUpskill', message: 'Are you looking to upskill in your current technology or explore a new field?', trigger: 'itTrainingUpskillInput' },
  { id: 'itTrainingUpskillInput', user: true, trigger: 'itTrainingStartDate' },

  // When to start the course
  { id: 'itTrainingStartDate', message: 'How soon are you looking to start a new course? (e.g., within a week, within a month, just exploring)', trigger: 'itTrainingStartDateInput' },
  { id: 'itTrainingStartDateInput', user: true, trigger: 'itTrainingCertification' },

  // Certification aim
  { id: 'itTrainingCertification', message: 'Is there a particular certification you’re aiming for with your training?', trigger: 'itTrainingCertificationInput' },
  { id: 'itTrainingCertificationInput', user: true, trigger: 'itTrainingLearningPreference' },

  // Learning preference: instructor-led, self-paced, or blend
  { id: 'itTrainingLearningPreference', message: 'Would you prefer live instructor-led sessions, self-paced learning, or a blend of both?', trigger: 'itTrainingLearningPreferenceInput' },
  { id: 'itTrainingLearningPreferenceInput', user: true, trigger: 'itTrainingTrial' },

  // Free trial or demo session
  { id: 'itTrainingTrial', message: 'Would you like a free trial or a demo session to get a feel for the course?', trigger: 'itTrainingTrialInput' },
  { id: 'itTrainingTrialInput', user: true, trigger: 'itTrainingMoreInfo' },

  // Link to course information
  { 
    id: 'itTrainingMoreInfo', 
    message: 'For more details, please check out our complete course information here: [https://www.nybbletechnosoft.com/home/]', 
    trigger: 'itTrainingFinalQuestion'
  },

  // Final question about contacting directly
  { 
    id: 'itTrainingFinalQuestion', 
    message: 'Thank you for your interest! Do you have any questions about the courses, or would you like our team to contact you directly?', 
    trigger: 'itTrainingFinalInput' 
  },
  { id: 'itTrainingFinalInput', user: true, trigger: 'itTrainingEnd' },

  // End message
  { 
    id: 'itTrainingEnd', 
    message: 'Thank you! Our team will get in touch with you shortly. Is there anything else I can assist you with for now? You can reach us at India: +91-7022374614 or USA: +1-800-216-8930 (Toll Free) for course-related inquiries.', 
    end: true 
  },





  
    // IT Staffing Section
    {
      id: 'staffing1',
      message: 'Greetings from NTS India! How can I assist you today?',
      trigger: 'askName'
    },
    {
      id: 'askName',
      message: 'May I have your name, please?',
      trigger: 'getName',
    },
    {
      id: 'getName',
      user: true,
      trigger: 'askEmail',
    },
    {
      id: 'askEmail',
      message: 'Could you share your email ID for us to reach out?',
      trigger: 'getEmail',
    },
    {
      id: 'getEmail',
      user: true,
      trigger: 'askMobile',
    },
    {
      id: 'askMobile',
      message: 'Please provide your mobile number so we can stay in touch.',
      trigger: 'getMobile',
    },
    {
      id: 'getMobile',
      user: true,
      trigger: 'askServiceType',
    },
    {
      id: 'askServiceType',
      message: 'Are you looking for staffing, hiring, or outsourcing services today?',
      trigger: 'getServiceType',
    },
    {
      id: 'getServiceType',
      options: [
        { value: 'Staffing', label: 'Staffing', trigger: 'askLocationPreference' },
        { value: 'Hiring', label: 'Hiring', trigger: 'askLocationPreference' },
        { value: 'Outsourcing', label: 'Outsourcing', trigger: 'askLocationPreference' }
      ]
    },
    {
      id: 'askLocationPreference',
      message: 'Could you let us know if you’re enquiring about job opportunities in India or International?',
      trigger: 'getLocationPreference',
    },
    {
      id: 'getLocationPreference',
      options: [
        { value: 'India', label: 'India', trigger: 'askJobLocation' },
        { value: 'International', label: 'International', trigger: 'askJobLocation' }
      ]
    },
    {
      id: 'askJobLocation',
      message: 'If you’re interested in job opportunities, are you looking for roles in India or internationally?',
      trigger: 'getJobLocation',
    },
    {
      id: 'getJobLocation',
      options: [
        { value: 'India', label: 'India', trigger: 'askExperienceLevel' },
        { value: 'International', label: 'International', trigger: 'askExperienceLevel' }
      ]
    },
    {
      id: 'askExperienceLevel',
      message: 'Can you tell me if you’re a fresher or have previous experience?',
      trigger: 'getExperienceLevel',
    },
    {
      id: 'getExperienceLevel',
      options: [
        { value: 'Fresher', label: 'Fresher', trigger: 'finalMessage' },
        { value: 'Experienced', label: 'Experienced', trigger: 'finalMessage' }
      ]
    },
    {
      id: 'finalMessage',
      message: 'For more information, feel free to explore our website and apply online: https://www.nybbletechnosoft.com/home. Thank you for connecting with us! Is there anything else I can assist you with?',
      end: true
    },
  



    
    // Web Development Section
    // Initial greeting for Web Development
      {
        id: 'webDev1',
        message: 'Hi! Welcome to Nybble Technosoft. How can we assist you today?',
        trigger: 'webDevName'
      },
      
      // Ask for user's name
      {
        id: 'webDevName',
        message: 'Could you please share your name?',
        trigger: 'webDevNameInput'
      },
      {
        id: 'webDevNameInput',
        user: true,
        trigger: 'webDevEmail'
      },
      
      // Ask for user's email
      {
        id: 'webDevEmail',
        message: 'May I have your email address for further communication?',
        trigger: 'webDevEmailInput'
      },
      {
        id: 'webDevEmailInput',
        user: true,
        trigger: 'webDevContact'
      },
      
      // Ask for user's mobile number
      {
        id: 'webDevContact',
        message: 'Could you also provide your mobile number?',
        trigger: 'webDevContactInput'
      },
      {
        id: 'webDevContactInput',
        user: true,
        trigger: 'webDevPurpose'
      },
      
      // Ask what brings the user to the website
      {
        id: 'webDevPurpose',
        message: 'What brings you to our website today?',
        trigger: 'webDevPurposeInput'
      },
      {
        id: 'webDevPurposeInput',
        user: true,
        trigger: 'webDevLookingServices'
      },
      
      // Ask if the user is looking for web development services
      {
        id: 'webDevLookingServices',
        message: 'Are you currently looking for web development services?',
        trigger: 'webDevLookingServicesInput'
      },
      {
        id: 'webDevLookingServicesInput',
        options: [
          { value: 'Yes', label: 'Yes', trigger: 'webDevProjectType' },
          { value: 'No', label: 'No', trigger: 'webDevEnd' }
        ]
      },
      
      // Ask for the type of web development project
      {
        id: 'webDevProjectType',
        message: 'What type of web development project are you interested in?',
        trigger: 'webDevProjectTypeInput'
      },
      {
        id: 'webDevProjectTypeInput',
        options: [
          { value: 'Website redesign', label: 'Website redesign', trigger: 'webDevGoal' },
          { value: 'Custom web application', label: 'Custom web application', trigger: 'webDevGoal' },
          { value: 'E-commerce solution', label: 'E-commerce solution', trigger: 'webDevGoal' },
          { value: 'Mobile app development', label: 'Mobile app development', trigger: 'webDevGoal' }
        ]
      },
      
      // Ask for the primary goal of the web project
      {
        id: 'webDevGoal',
        message: 'What is the primary goal of your web project?',
        trigger: 'webDevGoalInput'
      },
      {
        id: 'webDevGoalInput',
        options: [
          { value: 'Increase conversions', label: 'Increase conversions', trigger: 'webDevTechStack' },
          { value: 'Improve user experience', label: 'Improve user experience', trigger: 'webDevTechStack' },
          { value: 'Enhance brand visibility', label: 'Enhance brand visibility', trigger: 'webDevTechStack' }
        ]
      },
      
      // Ask for the technology stack in mind
      {
        id: 'webDevTechStack',
        message: 'Do you have a specific technology stack in mind for your project?',
        trigger: 'webDevTechStackInput'
      },
      {
        id: 'webDevTechStackInput',
        options: [
          { value: 'React', label: 'React', trigger: 'webDevBudget' },
          { value: 'Angular', label: 'Angular', trigger: 'webDevBudget' },
          { value: 'Vue.js', label: 'Vue.js', trigger: 'webDevBudget' },
          { value: 'WordPress', label: 'WordPress', trigger: 'webDevBudget' }
        ]
      },
      
      // Ask for the estimated budget
      {
        id: 'webDevBudget',
        message: 'What is your estimated budget for this project?',
        trigger: 'webDevBudgetInput'
      },
      {
        id: 'webDevBudgetInput',
        user: true,
        trigger: 'webDevTimeline'
      },
      
      // Ask for the preferred timeline
      {
        id: 'webDevTimeline',
        message: 'Do you have a preferred timeline for project completion?',
        trigger: 'webDevTimelineInput'
      },
      {
        id: 'webDevTimelineInput',
        user: true,
        trigger: 'webDevBusinessDescription'
      },
      
      // Ask for business and primary objectives
      {
        id: 'webDevBusinessDescription',
        message: 'Could you describe your business and its primary objectives?',
        trigger: 'webDevBusinessDescriptionInput'
      },
      {
        id: 'webDevBusinessDescriptionInput',
        user: true,
        trigger: 'webDevTargetAudience'
      },
      
      // Ask for target audience
      {
        id: 'webDevTargetAudience',
        message: 'Who is your target audience for this project?',
        trigger: 'webDevTargetAudienceInput'
      },
      {
        id: 'webDevTargetAudienceInput',
        user: true,
        trigger: 'webDevFeatures'
      },
      
      // Ask for specific features
      {
        id: 'webDevFeatures',
        message: 'What specific features would you like for your web project?',
        trigger: 'webDevFeaturesInput'
      },
      {
        id: 'webDevFeaturesInput',
        options: [
          { value: 'Payment gateway', label: 'Payment gateway', trigger: 'webDevDesignGuidelines' },
          { value: 'User authentication', label: 'User authentication', trigger: 'webDevDesignGuidelines' },
          { value: 'Integration with third-party APIs', label: 'Integration with third-party APIs', trigger: 'webDevDesignGuidelines' }
        ]
      },
      
      // Ask for design or branding guidelines
      {
        id: 'webDevDesignGuidelines',
        message: 'Do you have any design or branding guidelines we should follow?',
        trigger: 'webDevDesignGuidelinesInput'
      },
      {
        id: 'webDevDesignGuidelinesInput',
        user: true,
        trigger: 'webDevMaintenance'
      },
      
      // Ask if ongoing maintenance and support is needed
      {
        id: 'webDevMaintenance',
        message: 'Will you need ongoing maintenance and support once the project is complete?',
        trigger: 'webDevMaintenanceInput'
      },
      {
        id: 'webDevMaintenanceInput',
        options: [
          { value: 'Yes', label: 'Yes', trigger: 'webDevQualification' },
          { value: 'No', label: 'No', trigger: 'webDevQualification' }
        ]
      },
      
      // Qualification and Next Steps
      {
        id: 'webDevQualification',
        message: 'Are you the primary decision-maker for this project?',
        trigger: 'webDevDecisionMaker'
      },
      {
        id: 'webDevDecisionMaker',
        options: [
          { value: 'Yes', label: 'Yes', trigger: 'webDevPreviousWork' },
          { value: 'No', label: 'No', trigger: 'webDevPreviousWork' }
        ]
      },
      
      {
        id: 'webDevPreviousWork',
        message: 'Have you previously worked with a web development company?',
        trigger: 'webDevPreviousWorkInput'
      },
      {
        id: 'webDevPreviousWorkInput',
        options: [
          { value: 'Yes', label: 'Yes', trigger: 'webDevCommunicationMethod' },
          { value: 'No', label: 'No', trigger: 'webDevCommunicationMethod' }
        ]
      },
      
      {
        id: 'webDevCommunicationMethod',
        message: 'What is your preferred method of communication for project discussions?',
        trigger: 'webDevCommunicationMethodInput'
      },
      {
        id: 'webDevCommunicationMethodInput',
        options: [
          { value: 'Email', label: 'Email', trigger: 'webDevScheduleConsultation' },
          { value: 'Phone', label: 'Phone', trigger: 'webDevScheduleConsultation' },
          { value: 'Video call', label: 'Video call', trigger: 'webDevScheduleConsultation' }
        ]
      },
      
      {
        id: 'webDevScheduleConsultation',
        message: 'Would you like to schedule a consultation to discuss your project in more detail?',
        trigger: 'webDevScheduleConsultationInput'
      },
      {
        id: 'webDevScheduleConsultationInput',
        options: [
          { value: 'Yes', label: 'Yes', trigger: 'webDevCustomProposal' },
          { value: 'No', label: 'No', trigger: 'webDevCustomProposal' }
        ]
      },
      
      {
        id: 'webDevCustomProposal',
        message: 'Can we prepare and send a custom proposal tailored for your project needs?',
        trigger: 'webDevCustomProposalInput'
      },
      {
        id: 'webDevCustomProposalInput',
        options: [
          { value: 'Yes', label: 'Yes', trigger: 'webDevEnd' },
          { value: 'No', label: 'No', trigger: 'webDevEnd' }
        ]
      },
      
      // Final message
      {
        id: 'webDevEnd',
        message: 'Thank you! Our team will get in touch with you shortly. Is there anything else I can assist you with?',
        end: true
      }
  ];

  const handleEnd = async ({ steps, values }) => {
    console.log("All steps:", steps); // Shows all steps and their responses
    console.log("User inputs:", values); // Shows a sequential list of user inputs
    const userData = {
      name:"",
      email:"",
      mobileNumber:"",
      service:""
    }

    if(values[0] === 'Study Abroad'){
      userData.service = 'Study Abroad'
      userData.name = values[1]
      userData.email = values[2]
      userData.mobileNumber = values[3]
    }else if(values[0] === 'Training'){
      userData.service = 'Training'
      userData.name = values[3].split(',')[0]
      userData.email = values[3].split(',')[1]
      userData.mobileNumber = values[3].split(',')[2]
    }else if(values[0] === 'IT Staffing'){
      userData.service = 'IT Staffing'
      userData.name = values[1]
      userData.email = values[2]
      userData.mobileNumber = values[3]
    }else if(values[0] === 'Web Development'){
      userData.service = 'Web Development'
      userData.name = values[1]
      userData.email = values[2]
      userData.mobileNumber = values[3]
    }

    console.log('userData>>>', userData)
    try {
       // Add data to Firebase Firestore
       await saveDocData(CONNECTWITHUS, userData);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const handleChatClose = () => {
    setIsChatOpen(false);
  };

  return (
    <Modal
        title={"Chat"}
        handleClose={handleChatClose}
        open={isChatOpen}
      >
         <div className="carousel-line">
         <div className="carousel-container">
         <div
            className="close-icon"
            onClick={() => setIsChatOpen(false)}
          >
            <CloseOutlined style={{ color: "white" }} />
        </div>
        <ChatBot steps={steps} handleEnd={handleEnd} />
        </div>
        </div>

     </Modal>
  )
}

export default Chatbot
